import React from "react";

const loadingTextJSON = {
  text: [
    { id: "1", char: "L", delay: 0.0 },
    { id: "2", char: "o", delay: 0.1 },
    { id: "3", char: "a", delay: 0.2 },
    { id: "4", char: "d", delay: 0.3 },
    { id: "5", char: "i", delay: 0.4 },
    { id: "6", char: "n", delay: 0.5 },
    { id: "7", char: "g", delay: 0.6 },
    { id: "8", char: ".", delay: 0.7 },
    { id: "9", char: ".", delay: 0.8 },
    { id: "10", char: ".", delay: 0.9 },
  ],
};

const PageLoader = () => {
  const loaderStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  const textStyle: React.CSSProperties = {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#333",
    display: "flex",
    gap: "0.2rem",
  };

  const fadeAnimation = (delay: number): React.CSSProperties => ({
    animation: "fade 1.5s infinite",
    animationDelay: `${delay}s`,
  });

  return (
    <div style={loaderStyle}>
      <div style={textStyle}>
        {loadingTextJSON.text.map((item) => (
          <span key={item.id} style={fadeAnimation(item.delay)}>
            {item.char}
          </span>
        ))}
      </div>
      <style>
        {`
          @keyframes fade {
            0% { opacity: 0.2; }
            50% { opacity: 1; }
            100% { opacity: 0.2; }
          }
        `}
      </style>
    </div>
  );
};

export default PageLoader;
