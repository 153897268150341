import React, { ReactNode, useEffect } from "react";
import apiInterceptor from "./apiInterceptor";
import { useAuth0 } from "@auth0/auth0-react";
import * as authHelper from "../helpers/AuthHelpers";
const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();
  useEffect(() => {
    const errInterceptor = (error: any) => {
      if (error.response && error.response.status === 401) {
        (async function () {
          if (isAuthenticated) {
            try {
              const accessToken = await getAccessTokenSilently();
              authHelper.setATSAccessToken(accessToken);
            } catch (error: any) {
              loginWithRedirect();
            }
          }
        })();
      } else if (error.response && error.response.status === 503) {
        window.alert("Service Unavailable");
      } else if (error.response) {
        window.alert("Something went wrong try again");
      }
    };

    const interceptor = apiInterceptor.interceptors.response.use(
      (response) => response,
      errInterceptor
    );

    return () => {
      apiInterceptor.interceptors.response.eject(interceptor);
    };
  }, [isAuthenticated, loginWithRedirect, getAccessTokenSilently]);

  return <>{children}</>;
};

export default AxiosInterceptor;
