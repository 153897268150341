import { Outlet } from "react-router-dom";
import { LayoutProvider } from "./layout/core";
import { MasterMenuComponent } from "./layout/MasterMenuComponent";
import { ThemeModeProvider } from "./hooks‬/theme";
import { MasterComponentInit } from "./layout/MasterComponentInit";
import { JobProvider } from "hooks‬/JobContext";

const App = () => {
  return (
    <LayoutProvider>
      <ThemeModeProvider>
        <JobProvider>
          {" "}
          <Outlet />
        </JobProvider>
        <MasterMenuComponent />
        <MasterComponentInit />
      </ThemeModeProvider>
    </LayoutProvider>
  );
};

export { App };
