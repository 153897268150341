import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import jobposter from "assets/images/createJobPoster.svg";
import { CreateJobFormContainer } from "../Jobs/components/ui-components/CreateJobFormLayout";
import CloseCrossIcons from "components/base/CloseCrossIcons";
import FullModalTitle from "components/base/FullModalTitle";
import SelectInput from "components/form/SelectInput";
import MultiSelectField from "components/form/MultiSelectField";
import FilledGrayButton from "components/button/FilledGrayButton";
import FilledButtons from "components/button/FilledButtons";
import ScheduleInterviewForm from "./ScheduleInterviewForm";
import { getAllJobs, getAllUnscheduledCandidate } from "services/jobAPIs";
import { useNavigate } from "react-router";
import { privateRoutes } from "constant/route/privateRoutes";

interface CandidateType {
  candidateApplicationGuid: string;
  jobDetailsGuid: string;
  candidateName: string;
}

const ScheduleInterview = () => {
  const numSteps = 3;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const [jobOption, setJobOption] = useState<any[]>([]);
  const [candidateOption, setCandidateOption] = useState<any[]>([]);
  const [candidatesToSchedule, setCandidatesToSchedule] = useState<
    CandidateType[]
  >([]);

  async function nextStepper() {
    setCurrentStep((prevStep) => prevStep + 1);
  }
  const backStep = () => {
    setCurrentStep((prevStep: any) =>
      prevStep >= numSteps ? 1 : prevStep - 1
    );
  };

  const initialValues: any = {
    selectJob: null,
    selectedCandidates: [],
  };

  const validationSchema = Yup.object({
    selectJob: Yup.object().nullable().required("Select a job option"),

    selectedCandidates: Yup.array()
      .min(1, "At least one candidate must be selected")
      .required("Candidates are required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setCandidatesToSchedule(
        values.selectedCandidates.map((item: any) => ({
          candidateApplicationGuid: item.guid,
          jobDetailsGuid: values.selectJob.value,
          candidateName: item.displayName,
        }))
      );
      setSubmitting(false);
      nextStepper();
    },
  });

  const getAllJobsHandler = async () => {
    try {
      await getAllJobs()
        .then((res: any) => {
          setJobOption(
            res.data.data.map((item: any) => ({
              label: item.jobDetails.displayName,
              value: item.jobDetails.jobDetailsGuid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllJobsHandler();
  }, []);

  const onChangeSelectedField = (target: any) => {
    formik.setFieldValue(target.target.name, {
      value: target.target.value,
      label: target.target.label,
    });
  };

  const candidate_applicationHandler = async (guid: string) => {
    try {
      await getAllUnscheduledCandidate(guid)
        .then((res: any) => {
          setCandidateOption(
            res.data.data.map((item: any) => ({
              displayName:
                item.candidateDetails.candidateDetails.firstName +
                " " +
                item.candidateDetails.candidateDetails.lastName,
              guid: item.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (formik.values.selectJob?.value)
      candidate_applicationHandler(formik.values.selectJob.value);

    // eslint-disable-next-line
  }, [formik.values.selectJob]);
  return (
    <CreateJobFormContainer>
      {currentStep === 1 && (
        <>
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 rounded-0 card order-lg-1">
            <div className="d-flex w-100 justify-content-end">
              <CloseCrossIcons
                setModalToggle={() => navigate(privateRoutes.interviews)}
              />
            </div>
            <div className="d-flex flex-center justify-content-center h-100 w-100 flex-column  ">
              <FullModalTitle
                title="Schedule interview"
                subTitle="Set up and manage interview sessions"
              />
              <div className="w-100">
                <div className="p-10">
                  <SelectInput
                    label={t("Job Name")}
                    labelClass="required"
                    name="selectJob"
                    selectedOption={formik.values.selectJob}
                    setSelectedOption={onChangeSelectedField}
                    options={jobOption}
                    error={
                      formik.touched.selectJob &&
                      Boolean(formik.errors.selectJob)
                    }
                    helperText={
                      formik.touched.selectJob && formik.errors.selectJob
                    }
                  />
                  <MultiSelectField
                    label={t("Candidates")}
                    labelClass="required"
                    field={{
                      name: "selectedCandidates",
                      value: formik.values.selectedCandidates,
                    }}
                    form={formik}
                    options={candidateOption}
                  />
                </div>

                <div className="d-flex justify-content-end pt-4 gap-4">
                  <div>
                    {currentStep > 1 && (
                      <FilledGrayButton onClick={backStep} label={t("BACK")} />
                    )}
                  </div>
                  <div>
                    <FilledButtons
                      onClick={formik.submitForm}
                      label={t("NEXT")}
                      disabled={formik.isSubmitting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-lg-row-fluid  w-lg-50 bgi-size-cover bg-gray-300 bgi-position-center align-items-center justify-content-center order-1 order-lg-2 ">
            <img src={jobposter} className="h-250px" alt="poster" />
          </div>
        </>
      )}
      {currentStep === 2 && (
        <ScheduleInterviewForm
          candidatesToSchedule={candidatesToSchedule}
          backStep={backStep}
        />
      )}
      <ToastContainer />
    </CreateJobFormContainer>
  );
};

export default ScheduleInterview;
