import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
} from "react";
import {
  getAllCompanyBranches,
  getAllJobDescriptionSections,
  getJobCategories,
  getJobShifts,
  getJobTypes,
  getWorkModeLocation,
} from "services/jobAPIs";

type WithChildren = {
  children: ReactNode;
};
interface OptionsType {
  value: string;
  label: string;
}
interface JobDescriptionSectionsType {
  guid: string;
  displayName: string;
  isRequired: string;
}
interface SelectType {
  guid: string;
  displayName: string;
}
interface JobContextType {
  companyBranches: SelectType[];
  jobShift: SelectType[];
  jobDescriptionSections: JobDescriptionSectionsType[];
  jobCategories: OptionsType[];
  jobTypes: SelectType[];
  jobWorkModeLocation: SelectType[];
  fetchAllCompanyBranches: () => void;

  fetchJobShiftsHandler: () => void;
  fetchJobTypesHandler: () => void;
  fetchJobCategoriesHandler: () => void;
  fetchJobWorkModeLocationHandler: () => void;
  fetchAllJobDescriptionSectionsHandler: () => void;
}

export const JobContext = createContext<JobContextType>({
  companyBranches: [],
  jobShift: [],
  jobDescriptionSections: [],
  jobCategories: [],
  jobTypes: [],
  jobWorkModeLocation: [],
  fetchAllCompanyBranches: () => {},
  fetchJobShiftsHandler: () => {},
  fetchJobTypesHandler: () => {},
  fetchJobCategoriesHandler: () => {},
  fetchJobWorkModeLocationHandler: () => {},
  fetchAllJobDescriptionSectionsHandler: () => {},
});

export const useJobContext = () => {
  return useContext(JobContext);
};

export const JobProvider: React.FC<WithChildren> = ({ children }) => {
  const [jobShift, setJobShift] = useState<SelectType[]>([]);
  const [jobCategories, setJobCategories] = useState<OptionsType[]>([]);
  const [companyBranches, setCompanyBranches] = useState<SelectType[]>([]);
  const [jobTypes, setJobTypes] = useState<SelectType[]>([]);
  const [jobWorkModeLocation, setJobWorkModeLocation] = useState<SelectType[]>(
    []
  );

  const [jobDescriptionSections, setJobDescriptionSections] = useState<
    JobDescriptionSectionsType[]
  >([]);

  const fetchJobShiftsHandler = async () => {
    if (jobShift.length <= 0) {
      try {
        await getJobShifts()
          .then((response: any) => {
            setJobShift(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchJobTypesHandler = async () => {
    if (jobTypes.length <= 0) {
      try {
        await getJobTypes()
          .then((response: any) => {
            setJobTypes(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchJobCategoriesHandler = async () => {
    if (jobCategories.length <= 0) {
      try {
        await getJobCategories()
          .then((response: any) => {
            setJobCategories(
              response.data.data.map((item: any) => ({
                value: item.jobCategoryGuid,
                label: item.jobCategoryName,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchJobWorkModeLocationHandler = async () => {
    if (jobWorkModeLocation.length <= 0) {
      try {
        await getWorkModeLocation()
          .then((response: any) => {
            setJobWorkModeLocation(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchAllJobDescriptionSectionsHandler = async () => {
    if (jobDescriptionSections.length <= 0) {
      try {
        await getAllJobDescriptionSections()
          .then((response: any) => {
            setJobDescriptionSections(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchAllCompanyBranches = async () => {
    if (companyBranches.length <= 0) {
      try {
        await getAllCompanyBranches()
          .then((response: any) => {
            setCompanyBranches(
              response.data.data.map((item: any) => ({
                guid: item.guid,
                displayName: item.branchName,
              }))
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const value = useMemo(
    () => ({
      companyBranches,
      jobShift,
      jobDescriptionSections,
      jobCategories,
      jobTypes,
      jobWorkModeLocation,
      fetchAllCompanyBranches,

      fetchJobShiftsHandler,
      fetchJobTypesHandler,
      fetchJobCategoriesHandler,
      fetchJobWorkModeLocationHandler,
      fetchAllJobDescriptionSectionsHandler,
    }),
    // eslint-disable-next-line
    [
      companyBranches,
      jobShift,
      jobDescriptionSections,
      jobCategories,
      jobTypes,
      jobWorkModeLocation,
    ]
  );

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};
