import React, { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { App } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import LoginRedirect from "./LoginRedirect";
import PrivateMasterRoute from "./PrivateMasterRoute";
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  let element;

  if (isAuthenticated) {
    element = (
      <Route element={<App />}>
        <Route path="/*" element={<PrivateMasterRoute />} />
      </Route>
    );
  } else if (!isAuthenticated && !isLoading) {
    element = (
      <>
        <Route path="/*" element={<Navigate to="/login" />} />
        <Route path="/login" element={<LoginRedirect />} />;
      </>
    );
  } else {
    element = <Route index element={<></>} />;
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>{element}</Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
