import React, { FC, useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import CloseCrossIcons from "components/base/CloseCrossIcons";
import FullModalTitle from "components/base/FullModalTitle";
import FilledButtons from "components/button/FilledButtons";
import InputTextField from "components/form/InputTextField";
import MultiSelectField from "components/form/MultiSelectField";
import FilledGrayButton from "components/button/FilledGrayButton";
import { getAllInterviewers, scheduleInterview } from "services/interviewAPI";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { useCommonContext } from "hooks‬/CommonContext";
import { useNavigate } from "react-router";
import { privateRoutes } from "constant/route/privateRoutes";

interface CandidateType {
  candidateApplicationGuid: string;
  jobDetailsGuid: string;
  candidateName: string;
}

interface PropsType {
  candidatesToSchedule: CandidateType[];
  backStep: () => void;
}

// Validation Schema using Yup
const validationSchema = Yup.array().of(
  Yup.object().shape({
    selectedInterviewer: Yup.array()
      .min(1, "At least one interviewer is required")
      .required("Interviewer is required"),
    startTime: Yup.date()
      .required("Start time is required")
      .typeError("Invalid date format"),
    endTime: Yup.date()
      .required("End time is required")
      .typeError("Invalid date format")
      .test(
        "is-greater",
        "End time must be after start time",
        function (value) {
          const { startTime } = this.parent;
          return startTime && value && new Date(startTime) < new Date(value);
        }
      ),
  })
);

const ScheduleInterviewForm: FC<PropsType> = ({
  candidatesToSchedule,
  backStep,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [interviewerList, setInterviewerList] = useState<any[]>([]);

  const { showSuccessToast, showErrorToast } = useCommonContext();
  // Initial values for Formik
  const initialValues = candidatesToSchedule.map((candidate) => ({
    candidateApplicationGuid: candidate.candidateApplicationGuid,
    jobDetailsGuid: candidate.jobDetailsGuid,
    selectedInterviewer: [],
    startTime: "",
    endTime: "",
    candidateName: candidate.candidateName,
  }));

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);

      const sendToData = values.map((item: any) => ({
        candidateApplicationGuid: item.candidateApplicationGuid,
        jobDetailsGuid: item.jobDetailsGuid,
        startTime: item.startTime.replace("T", " "),
        endTime: item.endTime.replace("T", " "),
        interviewers: item.selectedInterviewer.map((item: any) => ({
          interviewerGuid: item.guid,
        })),
      }));

      await scheduleInterview(sendToData)
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            showErrorToast(response.data.statusDesc);
          } else {
            showSuccessToast(t("messages:CREATED.SUCCESSFULLY"));
            navigate(privateRoutes.interviews);
          }
        })
        .catch((error: any) => {
          setSubmitting(false);
        });
      setSubmitting(false);
    },
  });

  const getAllJobsHandler = async () => {
    try {
      await getAllInterviewers()
        .then((res: any) => {
          setInterviewerList(
            res.data.data.map((item: any) => ({
              guid: item.guid,
              displayName: item.firstName + " " + item.lastName,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllJobsHandler();
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 px-10  overflow-auto  order-2    bg-gray-300 order-lg-1">
      <div className="d-flex w-100 px-10 py-5 justify-content-end">
        <CloseCrossIcons
          setModalToggle={() => navigate(privateRoutes.interviews)}
        />
      </div>
      <div className="d-flex flex-center justify-content-center h-100 w-100 px-10 flex-column mb-10">
        <FullModalTitle
          title="Schedule interview"
          subTitle="Select interviewers and allocate time slots for scheduled interviews"
        />
        <div className="w-100">
          {formik.values.map((item: any, index: number) => (
            <div
              key={item.candidateApplicationGuid}
              className="row align-items-start justify-content-between mb-2 bg-white-dark radius-12 p-4"
            >
              <div className="col-3 px-2">
                <InputTextField
                  label={`Candidate ${index + 1}`}
                  value={item.candidateName}
                  name={`${index}.candidateName`}
                  onChange={formik.handleChange}
                  isDisable={true}
                />
              </div>
              <div className="col-4 px-2">
                <MultiSelectField
                  label={t("Interviewer")}
                  labelClass="required"
                  field={{
                    name: `${index}.selectedInterviewer`,
                    value: formik.values[index]?.selectedInterviewer || [],
                  }}
                  form={formik}
                  options={interviewerList}
                  error={Boolean(
                    (formik.errors[index] as FormikErrors<any>)
                      ?.selectedInterviewer &&
                      formik.touched[index]?.selectedInterviewer
                  )}
                  helperText={
                    formik.touched[index]?.selectedInterviewer &&
                    (formik.errors[index] as FormikErrors<any>)
                      ?.selectedInterviewer
                  }
                />
              </div>
              <div className="col-4  p-0 gap-0 m-0 d-flex justify-content-between">
                <div className="row p-0 justify-content-between w-100">
                  <div className="col-6">
                    <InputTextField
                      label={t("Start Time")}
                      labelClass="required"
                      type="datetime-local"
                      value={formik.values[index]?.startTime || ""}
                      onChange={formik.handleChange}
                      name={`${index}.startTime`}
                      error={Boolean(
                        (formik.errors[index] as FormikErrors<any>)
                          ?.startTime && formik.touched[index]?.startTime
                      )}
                      helperText={
                        formik.touched[index]?.startTime &&
                        (formik.errors[index] as FormikErrors<any>)?.startTime
                      }
                    />
                  </div>
                  <div className="col-6  ">
                    <InputTextField
                      label={t("End Time")}
                      name={`${index}.endTime`}
                      labelClass="required"
                      type="datetime-local"
                      value={formik.values[index]?.endTime || ""}
                      onChange={formik.handleChange}
                      error={Boolean(
                        (formik.errors[index] as FormikErrors<any>)?.endTime &&
                          formik.touched[index]?.endTime
                      )}
                      helperText={
                        formik.touched[index]?.endTime &&
                        (formik.errors[index] as FormikErrors<any>)?.endTime
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-end w-100 pt-4 gap-4 mx-10 mb-10">
          <div>
            <FilledGrayButton onClick={backStep} label={t("BACK")} />
          </div>
          <FilledButtons
            onClick={formik.submitForm}
            label={t("Schedule")}
            disabled={formik.isSubmitting}
          />
        </div>
      </div>{" "}
    </div>
  );
};

export default ScheduleInterviewForm;
