import React, { FC } from "react";

const FullModalTitle: FC<{ title: string; subTitle: string }> = ({
  title,
  subTitle,
}) => {
  return (
    <div className="w-100 text-center  mb-10">
      <div className=" text-center fw-bold  " style={{ fontSize: "32px" }}>
        {title}
      </div>
      <div className="text-center">{subTitle}</div>
    </div>
  );
};

export default FullModalTitle;
