import Select from "react-select";
import React, {
  FC,
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  RefObject,
  Dispatch,
  SetStateAction,
} from "react";

type Option = {
  target: {
    value: string;
    label: string;
    name?: string;
  };
};

interface InputTextFieldProps {
  placeholder?: string;
  id?: string;
  name?: string;
  isAutoFocus?: boolean;
  value?: string;
  size?: "sm" | "md" | "lg";
  label?: string;
  isDisable?: boolean;
  error?: boolean;
  helperText?: any;
  isvalid?: any;
  variant?: string;
  labelClass?: string;
  ref?: RefObject<HTMLInputElement>;
  forwardedRef?: RefObject<HTMLInputElement>;
  options: any[];
  selectedOption: {
    value: string;
    label: string;
    name?: string;
  } | null;
  setSelectedOption: Dispatch<SetStateAction<Option | null>>;
  isHideBorder?: boolean;
  onChange?: (event: ChangeEvent<any>) => void;
  onBlur?: (event: FocusEvent<any>) => void;
  onKeyDown?: (event: KeyboardEvent<any>) => void;
}

const SelectInput: FC<InputTextFieldProps> = (
  {
    id,
    label,
    size = "md",
    isAutoFocus = false,
    error,
    isDisable = false,
    helperText,
    variant = "outline",
    labelClass = " ",
    options,
    name,
    selectedOption,
    setSelectedOption,
    isHideBorder = false,
    placeholder = "",
    ...rest
  },
  ref
) => {
  return (
    <div className={`fv-row ${!isHideBorder && "mb-5"}`}>
      {label && (
        <label
          htmlFor={id}
          className={"form-label text-dark fs-6 m-2 text-muted " + labelClass}
        >
          {label}
        </label>
      )}

      <Select
        defaultValue={selectedOption}
        value={selectedOption}
        onChange={(e: any) =>
          setSelectedOption({
            target: {
              name: name,
              label: e.label,
              value: e.value,
            },
          })
        }
        className="react-select-styled react-select-solid is-valid "
        classNames={{
          control: () => "p-0 outline-select select-input-css",
        }}
        classNamePrefix="react-select "
        options={options}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            borderColor: error ? "red " : "#dbdfe9",
            boxShadow: "none",
            border: isHideBorder ? "0" : "1",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          colors: {
            ...theme.colors,
            primary: "grey",
          },
        })}
        components={{
          IndicatorSeparator: () => null,
        }}
      />

      {error && (
        <div className="fv-plugins-message-container ">
          <div className="fv-help-block">
            <span role="alert">
              {helperText.value ? helperText.value : helperText}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectInput;
