export type SidebarMenuItemType = {
  id: string;
  to: string;
  icon: string;
  titleKey: string;
  fontIcon?: string;
  subItems?: Array<{
    to: string;
    titleKey: string;
    hasBullet?: boolean;
  }>;
};

export const sidebarMenuItems: SidebarMenuItemType[] = [
  {
    id: "dashboard1",
    to: "/dashboard",
    icon: "home",
    titleKey: "DASHBOARD",
    fontIcon: "bi-app-indicator",
  },
  // {
  //   id: "dashboard2",
  //   to: "/client",
  //   icon: "clipboard",
  //   titleKey: "CLIENT",
  //   fontIcon: "bi-app-indicator",
  // },
  {
    id: "dashboard3",
    to: "/jobs",
    icon: "office-bag",
    titleKey: "JOBS",
    fontIcon: "bi-app-indicator",
  },
  {
    id: "dashboard4",
    to: "/candidates",
    icon: "profile-user",
    titleKey: "CANDIDATES",
    fontIcon: "bi-app-indicator",
  },
  {
    id: "dashboard4",
    to: "/interviews",
    icon: "calendar-2",
    titleKey: "Interviews",
    fontIcon: "bi-app-indicator",
  },
  // {
  //   id: "dashboard5",
  //   to: "/placement",
  //   icon: "people",
  //   titleKey: "PLACEMENT",
  //   fontIcon: "bi-app-indicator",
  // },
  // {
  //   id: "dashboard6",
  //   to: "/activities",
  //   icon: "airpod",
  //   titleKey: "ACTIVITIES",
  //   fontIcon: "bi-app-indicator",
  // },
  // {
  //   id: "dashboard7",
  //   to: "/inbox",
  //   icon: "directbox-default",
  //   titleKey: "INBOX",
  //   fontIcon: "bi-app-indicator",
  // },
  // {
  //   id: "dashboard8",
  //   to: "/reports",
  //   icon: "graph-up",
  //   titleKey: "REPORTS",
  //   fontIcon: "bi-app-indicator",
  // },
  // {
  //   id: "dashboard9",
  //   to: "/settings",
  //   icon: "setting-2",
  //   titleKey: "SETTINGS",
  //   fontIcon: "bi-app-indicator",
  // },
  // {
  //   id: "dashboard010",
  //   to: "/administration",
  //   icon: "lock-2",
  //   titleKey: "ADMINISTRATION",
  //   fontIcon: "bi-app-indicator",
  // },
];

export const interviewerSidebarMenuItems: SidebarMenuItemType[] = [
  {
    id: "dashboard1",
    to: "/dashboard",
    icon: "home",
    titleKey: "DASHBOARD",
    fontIcon: "bi-app-indicator",
  },
];
