import { MasterLayout } from "layout/MasterLayout";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";

const PrivateMasterRoute = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="/*" element={<PrivateRoutes />} />
      </Route>
    </Routes>
  );
};

export default PrivateMasterRoute;
