import { jobApiEndpoint } from "constant/APIConstant";
import apiInterceptor from "./apiInterceptor";

export function getAllJobDescriptionSections() {
  return apiInterceptor.get(jobApiEndpoint.jobDescription);
}

export function getJobTypes() {
  return apiInterceptor.get(jobApiEndpoint.jobTypes);
}

export function getJobShifts() {
  return apiInterceptor.get(jobApiEndpoint.jobShift);
}

export function getJobCategories() {
  return apiInterceptor.get(jobApiEndpoint.jobCategories);
}

export function getWorkModeLocation() {
  return apiInterceptor.get(jobApiEndpoint.workModeLocation);
}

export function createJob(json: any) {
  return apiInterceptor.post(jobApiEndpoint.job, json);
}

export function getAllCompanyBranches() {
  return apiInterceptor.get(jobApiEndpoint.companyBranches);
}

export function getAllJobs() {
  return apiInterceptor.get(jobApiEndpoint.job);
}

export function createCandidateByJob(jobGuid: string, formData: any) {
  return apiInterceptor.post(
    `${jobApiEndpoint.job}/${jobGuid}/candidate`,
    formData
  );
}

export function getAllUnscheduledCandidate(guid: string) {
  return apiInterceptor.get(`/ats/v1/candidate/unscheduled/${guid}`);
}
