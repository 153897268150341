import {
  FC,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import * as authHelper from "../helpers/AuthHelpers";
import { useAuth0 } from "@auth0/auth0-react";
import { extractLoggedUserRole, getCookie, setCookie } from "helpers/common";
const { REACT_APP_DOMAIN } = process.env;
const domain: string = REACT_APP_DOMAIN ?? "";
type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  systemLogout: () => void;
  loggedUserRole: any;
  loggedUserRoleSetter: (jsonData: any) => void;
};
const initAuthContextPropsState = {
  systemLogout: () => {},
  loggedUserRole: {},
  loggedUserRoleSetter: (jsonData: any) => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const { logout } = useAuth0();
  const [loggedUserRole, setLoggedUserRole] = useState<any>({});

  const systemLogout = () => {
    authHelper.removeAuth();
    setCookie("isLogout", "true", domain);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const loggedUserRoleSetter = (jsonData: any) => {
    setLoggedUserRole(jsonData);
  };

  const contextValue = useMemo(
    () => ({
      systemLogout,
      loggedUserRole,
      setLoggedUserRole,
      loggedUserRoleSetter,
    }),
    // eslint-disable-next-line
    [loggedUserRole] // Make sure to include loggedUserRole as a dependency if used within other hooks
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const {
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  const { loggedUserRoleSetter } = useAuth();

  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        try {
          setCookie("isLogout", "false", domain);
          if (getCookie("isLogout") === "true") {
            console.log("logout");
            loginWithRedirect();
          }
          const claims = await getIdTokenClaims();
          const accessToken = await getAccessTokenSilently();
          const decodedToken = jwtDecode<any>(accessToken);
          const userData = {
            user_email: decodedToken.user_email,
            user_guid: decodedToken.user_guid,
            user_role: extractLoggedUserRole(decodedToken.user_role),
          };
          loggedUserRoleSetter(userData);
          authHelper.setATSAccessToken(accessToken);
          const cvalue: string = claims?.["org_id"] ?? "";
          setCookie("udo-oi-amdmdx", cvalue);
        } catch (error: any) {
          loginWithRedirect();
        }
      }
    })();
    // eslint-disable-next-line
  }, [
    isAuthenticated,
    getIdTokenClaims,
    loginWithRedirect,
    getAccessTokenSilently,
  ]);

  return <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
